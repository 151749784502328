<!-- Control Observaciones -->

<template>
  <v-dialog class="ctrlobs" v-model="dialog" v-bind="Entorno.ventanaModal">
    <!-- Textfield a mostrar en pantalla -->
    <template v-slot:activator="{}">      
        <div :style="estilo">
            <v-icon @click="dialog = true">{{ Entorno.textarea.icono }}</v-icon> 
            <span :class="Entorno.textarea.class_titulo">{{ schema.label }}</span>
            <v-textarea                
                v-bind="Entorno.textarea"
                v-model="schema.value">                      
            </v-textarea>
        </div>    
    </template>

    <!-- Contenido a mostrar en la ventana -->
    <template>
      <v-card>
        <!-- Toolbar -->
        <v-toolbar v-bind="Entorno.toolbar">
          <!-- Botón para cerrar la ventana modal -->
          <v-btn
            v-if="btn_cerrarForm === true"
            icon
            @click="event_capture('cerrar_form')">          
                <v-icon>{{ "mdi-close" }}</v-icon>
          </v-btn>

          <!-- Título -->
          <v-toolbar-title class="white--text">{{ titulo }}</v-toolbar-title>
        </v-toolbar>

        <!-- Contenido -->
        <v-card-text>
          <!-- Textarea de observaciones -->
          <v-textarea
              class="textarea"
              v-model="schema.value"
              :readonly="dobleText"
              rows="12"
              rounded
              filled
              no-resize>
          </v-textarea>

          <!--- Textfield de introducción de texto -->
          <v-textarea
              v-if="dobleText && edicion"
              v-model="obs_to_add"
              label="Introduce texto:"
              :disabled="!edicion"
              no-resize
              clearable>          
          </v-textarea>
        </v-card-text>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
    export default {
      props: {
        schema: { type: Object, default:null },
        edicion: { type: Boolean, default: false },
        Entorno: { type: Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.ctrls.ctrlObs)); }},     
        titulo: { type: String, default: "Observaciones" },        
        dobleText: { type: Boolean, default: true },
        usuario: { type: Boolean, default: true },
        btn_cerrarForm: { type: Boolean, default: true }
      },

      data() {
        return {
          // variables de configuración
          //Entorno: this.$cfg.ctrls.ctrlObs,

          // variables del componente
          dialog: false,
          obs_to_add: null
        };
      },

      computed: {
        estilo()
        {
          return "width:" + this.Entorno.textarea.width;          
        }
      },

      methods: {
        event_capture(accion) {
          if (accion !== "cerrar_form") return;

          // cierro ventana de observaciones
          this.dialog = false;

          // si he introducido una observación, la añado al value del schema
          if (this.obs_to_add=== null) return (this.schema.value = this.schema.comp.valueaux);
          this.schema.value= this.obs_to_add === ""? "" : this.obs_to_add + "\n" + this.schema.comp.valueaux;
        }
      }
    };

</script>
